import React from "react";
import { Link } from "gatsby";
import "../css/textPage.css"

import Layout from "../components/layout";
import SEO from "../components/seo"

const Catering = () => {
    return (
        <Layout>
            <SEO title="Catering" />
            <div className="textPage">
                <h1>Catering.<br/>für Ihre Feier.</h1>
                <div className="textPageInfo">
                    <p>Für Ihre Feierleichkeit haben wir das passende Menü. Ob gemütliche Feier im Garten oder Geburtstagsparty, ob warm oder kalt, wir kümmern uns um Verpflegung und Getränke. Melden Sie sich zu Ihrer Veranstaltung.</p>
                    <Link to="/kontakt" className="link">Kontakt</Link>
                </div>
            </div>
        </Layout>
    )
}

export default Catering